<template>
  <div>
    <v-data-table
        :footer-props="{itemsPerPageOptions,itemsPerPageText}"
        :header-props="{ sortByText: `Trier par`}"
        :headers="headers"
        :items="stocks"
        class="elevation-1 ma-4 pa-4"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="totalStocks"
        :loading="loading"
        loading-text="Chargement des éléments"
        mobile-breakpoint="850"
        sort-by="etablissement.uai"
    >

      <!-- Tableau  ------------------------------------------------------------------------------------>
      <template v-slot:top>
        <v-toolbar-title
            class="mx-4"
            v-if="!$vuetify.breakpoint.smAndUp"
        >Stock
        </v-toolbar-title>

        <v-toolbar flat>
          <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Stock</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
              v-if="$vuetify.breakpoint.smAndUp"
          ></v-divider>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherche"
              single-line
              hide-details
              class="mx-4"
          ></v-text-field>
          <v-spacer></v-spacer>

          <NextButton
              width="250"
              :loading="exportLoading"
              color="primary"
              class="mr-3"
              id="outil-remise-a-zero"
              text="Outil de remise à zéro"
              @clicked="razStockDialog = !razStockDialog"
              :disable="$store.state.user.role !== 'GESTIONNAIRE_ARBS'"
              icon="mdi-broom"
          />
          <div class="btn btn-default hidden-md-and-down">
            <NextButton
                width="100%"
                color="primary"
                id="export-csv"
                @clicked="exportStocks"
                text="CSV"
                :loading="loading"
                icon="mdi-download"
            />

          </div>
        </v-toolbar>
      </template>

      <template v-slot:prepend="{ item }">
        <v-icon
            color="primary"
            v-if="item.type === 'lot'"
        >
          mdi-package-variant
        </v-icon>
        <v-img
            height="100px"
            width="80px"
            aspect-ratio="3/4"
            v-if="item.type === 'livre' && item.imgCouverture && $vuetify.breakpoint.smAndUp"
            :src="item.imgCouverture"
            class="ma-2"
        ></v-img>
        <v-icon
            color="primary"
            v-if="item.type === 'livre' && !item.imgCouverture "
        > mdi-book
        </v-icon>
        <v-icon
            color="primary"
            v-if="item.type === 'devis'"
        >
          mdi-school
        </v-icon>
      </template>
      <template v-slot:[`header.stock`]="{ header }">
        <Tooltip
            textToolTip="Attention, les stocks sont modifiables, mais cette action peut entraîner des désynchronisations irrémédiables">
          <v-icon color="warning">
            mdi-alert
          </v-icon>
          {{ header.text }}
          <v-icon color="warning">
            mdi-alert
          </v-icon>
        </Tooltip>
      </template>
      <template v-slot:[`item.offresTarifaire.canOrder`]="item">
        <div class="d-flex justify-center">
          <v-icon
              class="mr-2"
              :color="getColor(item.item.offresTarifaire.canOrder)"
          >
            {{ actifText(item.item.offresTarifaire.canOrder) }}
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.stock`]="props" v-if="$store.state.user.role !== 'GESTIONNAIRE_ETABLISSEMENT_AVANCE'">
        <v-edit-dialog
            lazy
            :return-value.sync="props.item.stock"
            large
            @save="save(props.item)"
            @cancel="cancel"
            save-text="Valider"
            cancel-text="Annuler"
        >
          {{ props.item.stock }}
          <template v-slot:input>
            <v-form
                ref="form"
                v-model="validInput"
            >
              <v-text-field
                  v-model="props.item.stock"
                  label="Modifier"
                  type="number"
                  single-line
              ></v-text-field>
            </v-form>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:no-data>
        <v-btn
            name="Bouton rafraichir"
            color="primary"
            @click="initialize"
        >
          <v-icon> mdi-refresh</v-icon>
        </v-btn>
      </template>

    </v-data-table>
    <v-snackbar
        v-model="snack"
        :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
            name="Bouton fermer"
            v-bind="attrs"
            text
            @click="snack = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <StockDialogRAZ :dialog="razStockDialog" @close="closeRAZ"/>
  </div>
</template>
<script>
import AdminService from "./../../services/AdminService";
import Tooltip from "./../Utility/Tooltip.vue";
import NextButton from "./../Utility/NextButton.vue";
import StockDialogRAZ from "@/components/StockComponent/StockDialogRAZ.vue";
import Utils from "@/utils/utils";

export default {
  components: {
    StockDialogRAZ,
    Tooltip,
    NextButton,
  },
  data: () => ({
    searchTimer: undefined,
    razStockDialog: false,
    search: "",
    validInput: false,
    headers: [
      {text: "UAI", value: "etablissement.uai"},
      {text: "Nom Etab*", value: "etablissement.nom"},
      {text: "ID Etab*", value: "etablissement.id"},
      {text: "Dernière modification", value: "dateModification"},
      {text: "Référence Divalto", value: "offresTarifaire.refOffreDivalto"},
      {text: "Titre", value: "offresTarifaire.ouvrage.nom"},
      {text: "Stock", value: "stock", align: "center", width: "120px"},
      {text: "Est valide", value: "offresTarifaire.canOrder", align: "center", width: "120px"},
    ],
    itemsPerPageOptions: [10, 25, 50, 1000],
    itemsPerPageText: `Lignes par page`,
    page: 1,
    stocks: [],
    stocksCSV: [],
    totalStocks: 0,
    numberOfPages: 0,
    pageCount: 0,
    loading: false,
    size: 10,
    options: {},
    snack: false,
    snackColor: "",
    snackText: "",
    props: {},
  }),
  computed: {},
  watch: {
    search: function () {
      if(this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.options.page = 1;
        this.initialize();
      }, 1000)
    },
    options: {
      handler() {
        this.initialize();
      },
    },
    deep: true,
  },

  mounted() {
    this.initialize();
  },

  methods: {
    async exportStocks() {
      try{
        this.exportLoading = true
        Utils.downloadFile((await AdminService.exportStocks()).data, "stocks.csv")
      } finally {
        this.exportLoading = false;
      }
    },
    getColor(Status) {
      if (Status === true) return "green";
      // else if (calories > 200) return "orange";
      else return "secondary";
    },
    actifText(item) {
      if (item === true) {
        return "mdi-check-circle-outline";
      } else {
        return "mdi-close-circle-outline";
      }
    },
    save(item) {
      this.$refs.form.validate();
      if (this.validInput) {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Modification sauvegardé";
        AdminService.putStock(
            item.etablissement.id,
            item.offresTarifaire.refOffreDivalto,
            item
        )
            .then(() => {
              this.loading = false;
              this.initialize();
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Une erreur est survenue",
              });
              this.loading = false;
            });
      } else {
        this.initialize();
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Modification annulé";
    },
    initialize() {
      this.loading = true;
      const {page, itemsPerPage, sortBy, sortDesc} = this.options;
      let pageNumber = page - 1;
      AdminService.getStock(
          pageNumber,
          itemsPerPage,
          sortBy,
          sortDesc,
          this.search
      )
          .then((element) => {
            this.loading = false;
            this.stocks = element.data.content;
            this.totalStocks = element.data.totalElements;
            this.numberOfPages = element.data.totalPages;
            this.stocks.forEach((element, id) => {
              this.stocks[id].dateModification = new Date(
                  this.stocks[id].dateModification
              ).toLocaleString("fr");
            });
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Une erreur est survenue",
            });
            this.loading = false;
          });
    },
    
    closeRAZ() {
      this.razStockDialog = false;
      this.initialize();
    }
  },
};
</script>
<style lang="scss" scoped>

</style>
