<template>
  <div>
    <v-dialog max-width="500px" v-model="dialog" persistent>
      <v-card>
        <v-form ref="stock-reset">
          <v-card-title>
            <h1 class="text-h5 primary--text">Outil de remise à zéro</h1>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <!-- Listes des établissements -->
                  <v-select
                      v-model="etablissement"
                      :items="etablissementsStock"
                      label="Etablissement"
                      :rules="[v => !!v || 'Etablissement requis']"
                      outlined
                      :disabled="loading"
                      dense
                      required>
                  </v-select>

                  <!-- Liste des types de stock -->
                  <v-select
                      v-model="niveauxSelectionnes"
                      :items="typesStock"
                      label="Sélectionner des niveaux"
                      multiple
                      outlined
                      :disabled="loading"
                      dense></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                tile
                dark
                elevation="0"
                @click="close"
                :loading="loading"
                color="primary">Annuler</v-btn>
            <v-btn
                tile
                dark
                @click="openDialogConfirmation"
                :loading="loading"
                elevation="0"
                color="secondary">Confirmer</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <ValidationModal
        text="Êtes-vous sûr de vouloir effectuer cette action ? (Cette action est irréversible)"
        :status="dialogConfirm"
        @validated="resetStock"
        @Canceled="annuler"
    />
  </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import ValidationModal from "@/components/Utility/ValidationModal.vue";

export default {
  name: "StockDialogRAZ",
  components: {ValidationModal},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      etablissements: [],
      etablissement: null,
      niveauxSelectionnes: [],
      loading: false,
      dialogConfirm: false,
    };
  },
  mounted() {
    // reset form data ref
    this.loadEstablishments();
    this.etablissements = [];
    this.etablissement = null;
    this.niveauxSelectionnes = [];
    this.$refs["stock-reset"].reset();
  },
  computed: {
    etablissementsStock() {
      // unique id for each item and sort by name
      console.log("fetching")
      return this.etablissements.map((stock) => stock.etablissement.id).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a - b);
    },
    typesStock() {
      // unique id for each item
      return this.etablissements.filter((stock) => stock.etablissement.id === this.etablissement).map((stock) => stock.offresTarifaire.ouvrage.classes).reduce((a, b) => a.concat(b), []);
    }
  },
  methods: {
    annuler() {
      this.dialogConfirm = false;
    },
    close() {
      this.etablissement = null;
      this.niveauxSelectionnes = [];
      this.$refs["stock-reset"].reset();
      this.$emit("close");
    },

    // Load the list of establishments in stock
    loadEstablishments() {
      AdminService.getStock(0,-1, "etablissement", "", "").then((response) => {
        this.etablissements = response.data.content;
      });
    },

    openDialogConfirmation() {
      if (this.niveauxSelectionnes.length === 0) {
        this.niveauxSelectionnes = this.typesStock;
      }
      this.$refs["stock-reset"].validate();
      if (this.$refs["stock-reset"].validate()) {
        this.dialogConfirm = true;
      }
    },

    resetStock() {
      // Validate form
      if (this.niveauxSelectionnes.length === 0) {
        this.niveauxSelectionnes = this.typesStock;
      }
      this.$refs["stock-reset"].validate();
      if (this.$refs["stock-reset"].validate()) {
        this.loading = true;
        let niveauxSelectionnesString = this.niveauxSelectionnes.map((niveau) => niveau.toString());
        AdminService.deleteStock(this.etablissement, {niveaux: niveauxSelectionnesString}).then(() => {
          this.loading = false;
          this.dialogConfirm = false;
          this.$emit("close");
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Le stock a été remis à zéro avec succès.",
          });
        }).catch(() => {
          this.loading = false;
          this.dialogConfirm = false;
        });
      }
    }
  },
}
</script>

<style scoped>

</style>