<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <StockTab />
  </v-card>
</template>

<script>
import StockTab from "../components/StockComponent/StockTab.vue";

export default {
  components: {
    StockTab,
  },
};
</script>